export const useCreatorNewStore = defineStore('creatorNewStore', () => {
  const creatorStore = useCreatorStore()
  const validationStatuses = computed(() => [
    creatorStore.hasPersonalInformation,
    creatorStore.hasProfessionalInformation,
    creatorStore.hasProfessionalInformation,
    creatorStore.hasProfessionalInformation,
  ])

  return {
    validationStatuses,
  }
})
